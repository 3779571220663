const isHybrid = navigator.userAgent.includes('Skybrid');
const home = 'global';

const commonRoutes = [
	{
		path: '*',
		redirect: { name: 'login' },
	},
	{
		path: '/entry',
		name: 'entry',
	},
	{
		path: '/',
		name: 'login',
		components: {
			default: () =>
				import(
					/* webpackChunkName: "v-cbnk-login", webpackPrefetch: true */ '@views/v-cbnk-login'
				),
		},
	},
	{
		path: '/home',
		name: 'home',
		redirect: { name: home },
	},
	{
		path: '/sso-rsi-form/:userId/:tokenSSO/:usuarioAgente?',
		props: { default: true },
		name: 'sso-rsi-form',
		components: {
			default: () =>
				import(
					/* webpackChunkName: "v-sso-rsi-form" */ '@views/v-sso-rsi-form'
				),
		},
	},
];

export default [
	...commonRoutes,
	{
		path: '/blocking-communication/',
		name: 'blocking-communication',
		props: { default: true },
		components: {
			default: () =>
				import(
					/* webpackChunkName: "v-cbnk-blocking-communication" */ '@views/v-cbnk-blocking-communication'
				),
		},
	},
	{
		path: '/main/',
		name: 'main',
		props: { default: true },
		components: {
			default: () =>
				import(/* webpackChunkName: "v-cbnk-main" */ '@views/v-cbnk-main'),
		},
		children: [
			{
				path: 'sso-rsi',
				name: 'sso-rsi',
				props: { primary: true },
				meta: {
					isFullWidth: true,
					transition: 'fade',
				},
				components: {
					primary: () =>
						import(
							/* webpackChunkName: "v-sso-rsi" */ '@views/v-sso-rsi'
						),
				},
			},
			{
				path: 'sso-lighthouse/:operative/:productId',
				name: 'sso-lighthouse',
				props: { primary: true },
				meta: {
					isFullWidth: true,
					transition: 'fade',
				},
				components: {
					primary: () =>
						import(
							/* webpackChunkName: "v-sso-lighthouse" */ '@views/v-sso-lighthouse'
						),
				},
			},
			{
				path: 'linea-caminos',
				name: 'linea-caminos',
				props: { primary: true },
				meta: {
					isFullWidth: true,
					transition: 'fade',
				},
				components: {
					primary: () =>
						import(
							/* webpackChunkName: "v-linea-caminos" */ '@views/v-linea-caminos'
						),
				},
			},
			{
				path: 'bolsa-caminos',
				name: 'bolsa-caminos',
				props: { primary: true },
				meta: {
					isFullWidth: true,
					transition: 'fade',
				},
				components: {
					primary: () =>
						import(
							/* webpackChunkName: "v-bolsa-caminos" */ '@views/v-bolsa-caminos'
						),
				},
			},
			{
				path: 'tax-info',
				name: 'tax-info',
				props: { primary: { action: 'InformacionFiscal' } },
				meta: {
					isFullWidth: true,
					transition: 'fade',
				},
				components: {
					primary: () =>
						import(
							/* webpackChunkName: "v-linea-caminos" */ '@views/v-linea-caminos'
						),
				},
			},
			{
				path: 'global',
				name: 'global',
				props: { primary: true },
				meta: {
					isFullWidth: true,
					entryPoint: isHybrid,
				},
				components: {
					primary: () =>
						import(/* webpackChunkName: "v-global" */ '@views/v-global'),
				},
			},
			{
				path: 'investment-account/:familyId/:productId',
				name: 'investment-account',
				props: { primary: true },
				meta: {
					isFullWidth: true,
					isNavDisabled: true,
				},
				components: {
					primary: () =>
						import(
							/* webpackChunkName: "v-investment-account" */ '@views/v-investment-account'
						),
				},
			},
			{
				path: 'apple-pay/:productId',
				name: 'apple-pay',
				props: {
					primary: true,
					secondary: true,
				},
				components: {
					primary: () =>
						import(/* webpackChunkName: "v-global" */ '@views/v-global'),
					secondary: () =>
						import(
							/* webpackChunkName: "v-apple-pay" */ '@views/v-apple-pay'
						),
				},
			},
			{
				path: 'google-pay/:productId',
				name: 'google-pay',
				props: {
					primary: true,
					secondary: true,
				},
				components: {
					primary: () =>
						import(/* webpackChunkName: "v-global" */ '@views/v-global'),
					secondary: () =>
						import(
							/* webpackChunkName: "v-google-pay" */ '@views/v-google-pay'
						),
				},
			},
			{
				path: '/product-detail/:productId',
				name: 'products-detail',
				props: {
					primary: true,
					secondary: true,
				},
				components: {
					primary: () =>
						import(/* webpackChunkName: "v-global" */ '@views/v-global'),
					secondary: () =>
						import(
							/* webpackChunkName: "v-fam-products-detail" */ '@views/v-fam-products-detail'
						),
				},
			},
			{
				path: 'marketplace',
				name: 'marketplace',
				props: { primary: true },
				meta: { isFullWidth: true },
				components: {
					primary: () =>
						import(
							/* webpackChunkName: "v-marketplace" */ '@views/v-marketplace'
						),
				},
			},
			{
				path: 'marketplace/category/:categoryId/:categoryName?',
				name: 'marketplace-category',
				props: {
					primary: true,
					secondary: true,
				},
				components: {
					primary: () =>
						import(
							/* webpackChunkName: "v-marketplace" */ '@views/v-marketplace'
						),
					secondary: () =>
						import(
							/* webpackChunkName: "v-marketplace-category" */ '@views/v-marketplace-category'
						),
				},
			},
			{
				path: 'marketplace/detail/:productId',
				name: 'marketplace-detail',
				props: {
					primary: true,
					secondary: true,
				},
				meta: { isProductPack: false },
				components: {
					primary: () =>
						import(
							/* webpackChunkName: "v-marketplace" */ '@views/v-marketplace'
						),
					secondary: () =>
						import(
							/* webpackChunkName: "v-marketplace-detail" */ '@views/v-marketplace-detail'
						),
				},
			},
			{
				path: 'marketplace/detail/pack/:productId',
				name: 'marketplace-pack-detail',
				props: {
					primary: true,
					secondary: true,
				},
				meta: { isProductPack: true },
				components: {
					primary: () =>
						import(
							/* webpackChunkName: "v-marketplace" */ '@views/v-marketplace'
						),
					secondary: () =>
						import(
							/* webpackChunkName: "v-marketplace-pack-detail" */ '@views/v-marketplace-pack-detail'
						),
				},
			},
			{
				path: 'customer-service',
				name: 'customer-service',
				props: { primary: true },
				components: {
					primary: () =>
						import(
							/* webpackChunkName: "v-customer-service" */ '@views/v-customer-service'
						),
				},
			},
			{
				path: 'customer-chat',
				name: 'customer-chat',
				props: {
					primary: true,
					secondary: true,
				},
				components: {
					primary: () =>
						import(
							/* webpackChunkName: "v-customer-service" */ '@views/v-customer-service'
						),
					secondary: () =>
						import(
							/* webpackChunkName: "v-customer-chat" */ '@views/v-customer-chat'
						),
				},
			},
			{
				path: 'customer-online',
				name: 'customer-online',
				props: {
					primary: true,
					secondary: true,
				},
				components: {
					primary: () =>
						import(
							/* webpackChunkName: "v-customer-service" */ '@views/v-customer-service'
						),
					secondary: () =>
						import(
							/* webpackChunkName: "v-customer-online" */ '@views/v-customer-online'
						),
				},
			},
			{
				path: 'customer-offices',
				name: 'customer-offices',
				props: {
					primary: true,
					secondary: true,
				},
				components: {
					primary: () =>
						import(
							/* webpackChunkName: "v-customer-service" */ '@views/v-customer-service'
						),
					secondary: () =>
						import(
							/* webpackChunkName: "v-customer-offices" */ '@views/v-customer-offices'
						),
				},
			},
			{
				path: 'sirvase',
				name: 'sirvase',
				props: {
					primary: true,
					secondary: true,
				},
				meta: { transition: 'fade' },
				components: {
					primary: () =>
						import(
							/* webpackChunkName: "v-customer-service" */ '@views/v-customer-service'
						),
					secondary: () =>
						import(
							/* webpackChunkName: "v-sirvase" */ '@views/v-sirvase'
						),
				},
			},
			{
				path: 'sirvase/welcome',
				name: 'sirvase-welcome',
				props: {
					primary: true,
					secondary: true,
				},
				components: {
					primary: () =>
						import(
							/* webpackChunkName: "v-customer-service" */ '@views/v-customer-service'
						),
					secondary: () =>
						import(
							/* webpackChunkName: "v-sirvase-welcome" */ '@views/v-sirvase-welcome'
						),
				},
			},
			{
				path: 'sirvase-dashboard',
				name: 'sirvase-dashboard',
				props: {
					primary: true,
					secondary: true,
				},
				components: {
					primary: () =>
						import(
							/* webpackChunkName: "v-customer-service" */ '@views/v-customer-service'
						),
					secondary: () =>
						import(
							/* webpackChunkName: "v-sirvase-dashboard" */ '@views/v-sirvase-dashboard'
						),
				},
			},
			{
				path: 'sirvase-detail/:requestId',
				name: 'sirvase-detail',
				props: {
					primary: true,
					secondary: true,
				},
				components: {
					primary: () =>
						import(
							/* webpackChunkName: "v-customer-service" */ '@views/v-customer-service'
						),
					secondary: () =>
						import(
							/* webpackChunkName: "v-sirvase-detail" */ '@views/v-sirvase-detail'
						),
				},
			},
			{
				path: 'sirvase-create',
				name: 'sirvase-create',
				props: {
					primary: true,
					secondary: true,
				},
				components: {
					primary: () =>
						import(
							/* webpackChunkName: "v-customer-service" */ '@views/v-customer-service'
						),
					secondary: () =>
						import(
							/* webpackChunkName: "v-sirvase-create" */ '@views/v-sirvase-create'
						),
				},
			},
			{
				path: 'feedback',
				name: 'feedback',
				props: { primary: true },
				meta: { isNavDisabled: true },
				components: {
					primary: () =>
						import(
							/* webpackChunkName: "v-feedback" */ '@views/v-feedback'
						),
				},
			},
			{
				path: 'product/:familyId/:productId/movement/:movementId',
				name: 'movement',
				props: {
					primary: true,
					secondary: true,
				},
				components: {
					primary: () =>
						import(/* webpackChunkName: "v-global" */ '@views/v-global'),
					secondary: () =>
						import(
							/* webpackChunkName: "v-movement" */ '@views/v-movement'
						),
				},
			},
			{
				path: 'product/:familyId/:productId/withholdings',
				name: 'withholdings',
				props: {
					primary: true,
					secondary: true,
				},
				components: {
					primary: () =>
						import(/* webpackChunkName: "v-global" */ '@views/v-global'),
					secondary: () =>
						import(
							/* webpackChunkName: "v-withholdings" */ '@views/v-withholdings'
						),
				},
			},
			{
				path: 'product/:familyId/:productId',
				name: 'product',
				props: {
					primary: true,
					secondary: true,
				},
				components: {
					primary: () =>
						import(/* webpackChunkName: "v-global" */ '@views/v-global'),
					secondary: () =>
						import(
							/* webpackChunkName: "v-product" */ '@views/v-product'
						),
				},
			},
			{
				path: 'product/:familyId/:productId/imposition/:movementId',
				name: 'imposition',
				props: {
					primary: true,
					secondary: true,
				},
				components: {
					primary: () =>
						import(/* webpackChunkName: "v-global" */ '@views/v-global'),
					secondary: () =>
						import(
							/* webpackChunkName: "v-imposition" */ '@views/v-imposition'
						),
				},
			},
			{
				path: 'product/financed-operation/:productId',
				name: 'financed-operation-detail',
				props: {
					primary: true,
					secondary: true,
				},
				components: {
					primary: () =>
						import(/* webpackChunkName: "v-global" */ '@views/v-global'),
					secondary: () =>
						import(
							/* webpackChunkName: "v-financed-operation-detail" */ '@views/v-financed-operation-detail'
						),
				},
			},
			{
				path: 'product/:familyId/:productId/asset/:assetId',
				name: 'asset',
				props: {
					primary: true,
					secondary: true,
				},
				components: {
					primary: () =>
						import(/* webpackChunkName: "v-global" */ '@views/v-global'),
					secondary: () =>
						import(/* webpackChunkName: "v-asset" */ '@views/v-asset'),
				},
			},
			{
				path: 'product/:familyId/:productId/investment-asset/:assetId',
				name: 'investment-asset',
				props: {
					primary: true,
					secondary: true,
				},
				components: {
					primary: () =>
						import(/* webpackChunkName: "v-global" */ '@views/v-global'),
					secondary: () =>
						import(
							/* webpackChunkName: "v-investment-asset" */ '@views/v-investment-asset'
						),
				},
			},
			{
				path: 'product/:familyId/:productId/composition/:type/:tab?',
				name: 'composition',
				props: {
					primary: true,
					secondary: true,
				},
				components: {
					primary: () =>
						import(/* webpackChunkName: "v-global" */ '@views/v-global'),
					secondary: () =>
						import(
							/* webpackChunkName: "v-composition" */ '@views/v-composition'
						),
				},
			},
			{
				path: 'product/:familyId/search-movements/:productId/',
				name: 'search-movements',
				props: {
					primary: true,
					secondary: true,
				},
				components: {
					primary: () =>
						import(/* webpackChunkName: "v-global" */ '@views/v-global'),
					secondary: () =>
						import(
							/* webpackChunkName: "v-movement" */ '@views/v-search-movements'
						),
				},
			},
			{
				path: 'product/:familyId/filter-investment/:productId/',
				name: 'filter-investment',
				props: { primary: true },
				meta: { isFullWidth: true },
				components: {
					primary: () =>
						import(
							/* webpackChunkName: "v-global" */ '@views/v-filter-investment'
						),
				},
			},
			{
				path: 'product/:familyId/:productId/detail',
				name: 'product-detail',
				props: {
					primary: true,
					secondary: true,
				},
				meta: { transition: 'fade' },
				components: {
					primary: () =>
						import(/* webpackChunkName: "v-global" */ '@views/v-global'),
					secondary: () =>
						import(
							/* webpackChunkName: "v-product-detail" */ '@views/v-product-detail'
						),
				},
			},
			{
				path: 'product/:familyId/:productId/investment-detail',
				name: 'investment-product-detail',
				props: { primary: true },
				meta: {
					isFullWidth: true,
					transition: 'fade',
				},
				components: {
					primary: () =>
						import(
							/* webpackChunkName: "v-product-detail" */ '@views/v-product-detail'
						),
				},
			},
			{
				path: 'product/investment/:productType/:productId',
				name: 'investment-detail',
				props: { primary: true },
				meta: { isFullWidth: true },
				components: {
					primary: () =>
						import(
							/* webpackChunkName: "v-investment-detail" */ '@views/v-investment-detail'
						),
				},
			},
			{
				path: 'product/:familyId/:productId/operative/:operativeType',
				name: 'card-operative',
				props: {
					primary: true,
					secondary: true,
				},
				meta: { transition: 'fade' },
				components: {
					primary: () =>
						import(/* webpackChunkName: "v-global" */ '@views/v-global'),
					secondary: () =>
						import(
							/* webpackChunkName: "v-product-detail" */ '@views/v-card-operative'
						),
				},
			},
			{
				path: 'product/:familyId/:productId/simulation',
				name: 'settlement-simulation',
				props: {
					primary: true,
					secondary: true,
				},
				meta: { transition: 'fade' },
				components: {
					primary: () =>
						import(/* webpackChunkName: "v-global" */ '@views/v-global'),
					secondary: () =>
						import(
							/* webpackChunkName: "v-product-detail" */ '@views/v-settlement-simulation'
						),
				},
			},
			{
				path: 'product/:familyId',
				name: 'product-group',
				props: {
					primary: true,
					secondary: true,
				},
				components: {
					primary: () =>
						import(/* webpackChunkName: "v-global" */ '@views/v-global'),
					secondary: () =>
						import(
							/* webpackChunkName: "v-product-group" */ '@views/v-product-group'
						),
				},
			},
			{
				path: 'product/:familyId/:productId/profiles',
				name: 'product-profiles',
				props: {
					primary: true,
					secondary: true,
				},
				components: {
					primary: () =>
						import(/* webpackChunkName: "v-global" */ '@views/v-global'),
					secondary: () =>
						import(
							/* webpackChunkName: "v-product-profiles" */ '@views/v-product-profiles'
						),
				},
			},
			{
				path: 'product/:familyId/:productId/debits',
				name: 'debits',
				props: {
					primary: true,
					secondary: true,
				},
				components: {
					primary: () =>
						import(/* webpackChunkName: "v-global" */ '@views/v-global'),
					secondary: () =>
						import(/* webpackChunkName: "v-debits" */ '@views/v-debits'),
				},
			},
			{
				path: 'product/:familyId/:productId/debits/:creditorId',
				name: 'creditor-detail',
				props: {
					primary: true,
					secondary: true,
				},
				components: {
					primary: () =>
						import(/* webpackChunkName: "v-global" */ '@views/v-global'),
					secondary: () =>
						import(
							/* webpackChunkName: "v-creditor-detail" */ '@views/v-creditor-detail'
						),
				},
			},
			{
				path: 'product/:familyId/:productId/debits/:movementId/:creditorId?',
				name: 'movement-debit',
				props: {
					primary: true,
					secondary: true,
				},
				components: {
					primary: () =>
						import(/* webpackChunkName: "v-global" */ '@views/v-global'),
					secondary: () =>
						import(
							/* webpackChunkName: "v-movement-debit" */ '@views/v-movement-debit'
						),
				},
			},
			{
				path: 'premium/:familyId',
				name: 'premium',
				props: {
					primary: true,
					secondary: true,
				},
				components: {
					primary: () =>
						import(/* webpackChunkName: "v-global" */ '@views/v-global'),
					secondary: () =>
						import(
							/* webpackChunkName: "v-premium" */ '@views/v-premium'
						),
				},
			},
			{
				path: 'amortization-table/:productId',
				name: 'amortization-table',
				props: {
					primary: true,
					secondary: true,
				},
				components: {
					primary: () =>
						import(/* webpackChunkName: "v-global" */ '@views/v-global'),
					secondary: () =>
						import(
							/* webpackChunkName: "v-amortization-table" */ '@views/v-amortization-table'
						),
				},
			},
			{
				path: 'product/:familyId/:productId/receipts-table',
				name: 'receipts-table',
				props: {
					primary: true,
					secondary: true,
				},
				components: {
					primary: () =>
						import(/* webpackChunkName: "v-global" */ '@views/v-global'),
					secondary: () =>
						import(
							/* webpackChunkName: "v-receipts-table" */ '@views/v-receipts-table'
						),
				},
			},
			{
				path: 'personal-area',
				name: 'personal-area',
				props: { primary: true },
				components: {
					primary: () =>
						import(
							/* webpackChunkName: "v-personal-area" */ '@views/v-personal-area'
						),
				},
			},
			{
				path: 'personal-area/notifications',
				name: 'notifications',
				props: {
					primary: true,
					secondary: true,
				},
				components: {
					primary: () =>
						import(
							/* webpackChunkName: "v-personal-area" */ '@views/v-personal-area'
						),
					secondary: () =>
						import(
							/* webpackChunkName: "v-notifications" */ '@views/v-notifications'
						),
				},
			},
			{
				path: 'personal-area/security-and-privacy',
				name: 'security-and-privacy',
				props: {
					primary: true,
					secondary: true,
				},
				components: {
					primary: () =>
						import(
							/* webpackChunkName: "v-personal-area" */ '@views/v-personal-area'
						),
					secondary: () =>
						import(
							/* webpackChunkName: "v-security-and-privacy" */ '@views/v-security-and-privacy'
						),
				},
			},
			{
				path: 'personal-area/personal-details',
				name: 'personal-details',
				props: {
					primary: true,
					secondary: true,
				},
				meta: { transition: 'fade' },
				components: {
					primary: () =>
						import(
							/* webpackChunkName: "v-personal-area" */ '@views/v-personal-area'
						),
					secondary: () =>
						import(
							/* webpackChunkName: "v-personal-details" */ '@views/v-personal-details'
						),
				},
			},
			{
				path: 'personal-area/security-and-privacy/change-password',
				name: 'change-password',
				props: {
					primary: true,
					secondary: true,
				},
				components: {
					primary: () =>
						import(
							/* webpackChunkName: "v-personal-area" */ '@views/v-personal-area'
						),
					secondary: () =>
						import(
							/* webpackChunkName: "v-change-password" */ '@views/v-change-password'
						),
				},
			},
			{
				path: 'personal-area/security-and-privacy/gdpr',
				name: 'gdpr',
				props: {
					primary: true,
					secondary: true,
				},
				components: {
					primary: () =>
						import(
							/* webpackChunkName: "v-personal-area" */ '@views/v-personal-area'
						),
					secondary: () =>
						import(/* webpackChunkName: "v-gdpr" */ '@views/v-gdpr'),
				},
			},
			{
				path: 'personal-area/security-and-privacy/block',
				name: 'block',
				props: {
					primary: true,
					secondary: true,
				},
				components: {
					primary: () =>
						import(
							/* webpackChunkName: "v-personal-area" */ '@views/v-personal-area'
						),
					secondary: () =>
						import(/* webpackChunkName: "v-block" */ '@views/v-block'),
				},
			},
			{
				path: 'personal-area/devices-list',
				name: 'devices-list',
				props: {
					primary: true,
					secondary: true,
				},
				components: {
					primary: () =>
						import(
							/* webpackChunkName: "v-personal-area" */ '@views/v-personal-area'
						),
					secondary: () =>
						import(
							/* webpackChunkName: "v-devices-list" */ '@views/v-devices-list'
						),
				},
			},
			{
				path: 'personal-area/devices-list/device-sessions/:deviceId',
				name: 'device-sessions',
				props: {
					primary: true,
					secondary: true,
				},
				components: {
					primary: () =>
						import(
							/* webpackChunkName: "v-personal-area" */ '@views/v-personal-area'
						),
					secondary: () =>
						import(
							/* webpackChunkName: "v-device-sessions" */ '@views/v-device-sessions'
						),
				},
			},
			{
				path: 'personal-area/language',
				name: 'language',
				props: {
					primary: true,
					secondary: true,
				},
				components: {
					primary: () =>
						import(
							/* webpackChunkName: "v-personal-area" */ '@views/v-personal-area'
						),
					secondary: () =>
						import(
							/* webpackChunkName: "v-language" */ '@views/v-language'
						),
				},
			},
			{
				path: 'personal-area/mas-cbnk',
				name: 'mas-cbnk',
				meta: { isFullWidth: true, isNavDisabled: true },
				props: {
					primary: (route) => ({
						relatedCustomerData: route.params.relatedCustomerData,
					}),
				},

				components: {
					primary: () =>
						import(
							/* webpackChunkName: "v-mas-cbnk" */ '@views/v-mas-cbnk'
						),
				},
			},
			{
				path: 'transfers',
				name: 'transfers',
				props: { primary: true },
				components: {
					primary: () =>
						import(
							/* webpackChunkName: "v-transfers" */ '@views/v-transfers'
						),
				},
			},
			// {
			// 	path: 'transfer/:action/:type?/:transferId?',
			// 	name: 'transfer',
			// 	props: {
			// 		primary: true,
			// 		secondary: true,
			// 	},
			// 	components: {
			// 		primary: () =>
			// 			import(
			// 				/* webpackChunkName: "v-transfers" */ '@views/v-transfers'
			// 			),
			// 		secondary: () =>
			// 			import(
			// 				/* webpackChunkName: "v-transfer" */ '@views/v-transfer'
			// 			),
			// 	},
			// },
			{
				path: 'transfer/:action/:type?/:transferId?',
				name: 'transfer',
				props: { primary: true },
				meta: { isFullWidth: true, isNavDisabled: true },
				components: {
					primary: () =>
						import(
							/* webpackChunkName: "v-cbnk-transfer" */ '@views/v-cbnk-transfer'
						),
				},
			},
			{
				path: 'my-transfers/:productId?',
				name: 'my-transfers',
				props: {
					primary: true,
					secondary: true,
				},
				components: {
					primary: () =>
						import(
							/* webpackChunkName: "v-transfers" */ '@views/v-transfers'
						),
					secondary: () =>
						import(
							/* webpackChunkName: "v-my-transfers" */ '@views/v-my-transfers'
						),
				},
			},
			{
				path: 'my-transfers/:productId/:type/:transferId?',
				name: 'transfer-detail',
				props: {
					primary: true,
					secondary: true,
				},
				components: {
					primary: () =>
						import(
							/* webpackChunkName: "v-transfers" */ '@views/v-transfers'
						),
					secondary: () =>
						import(
							/* webpackChunkName: "v-transfer-detail" */ '@views/v-transfer-detail'
						),
				},
			},
			{
				path: 'bizum',
				name: 'bizum',
				props: {
					primary: true,
					secondary: true,
				},
				meta: { transition: 'fade' },
				components: {
					primary: () =>
						import(
							/* webpackChunkName: "v-transfers" */ '@views/v-transfers'
						),
					secondary: () =>
						import(/* webpackChunkName: "v-bizum" */ '@views/v-bizum'),
				},
			},
			{
				path: 'bizum/welcome',
				name: 'bizum-welcome',
				props: {
					primary: true,
					secondary: true,
				},
				components: {
					primary: () =>
						import(
							/* webpackChunkName: "v-transfers" */ '@views/v-transfers'
						),
					secondary: () =>
						import(
							/* webpackChunkName: "v-bizum-welcome" */ '@views/v-bizum-welcome'
						),
				},
			},
			{
				path: 'bizum/register/:productId',
				name: 'bizum-register',
				props: {
					primary: true,
					secondary: true,
				},
				components: {
					primary: () =>
						import(
							/* webpackChunkName: "v-transfers" */ '@views/v-transfers'
						),
					secondary: () =>
						import(
							/* webpackChunkName: "v-bizum-register" */ '@views/v-bizum-register'
						),
				},
			},
			{
				path: 'bizum/settings',
				name: 'bizum-settings',
				props: {
					primary: true,
					secondary: true,
				},
				components: {
					primary: () =>
						import(
							/* webpackChunkName: "v-transfers" */ '@views/v-transfers'
						),
					secondary: () =>
						import(
							/* webpackChunkName: "v-bizum-settings" */ '@views/v-bizum-settings'
						),
				},
			},
			{
				path: 'bizum/connections',
				name: 'bizum-connections',
				props: {
					primary: true,
					secondary: true,
				},
				components: {
					primary: () =>
						import(
							/* webpackChunkName: "v-transfers" */ '@views/v-transfers'
						),
					secondary: () =>
						import(
							/* webpackChunkName: "v-bizum-connections" */ '@views/v-bizum-connections'
						),
				},
			},
			{
				path: 'bizum/connections-detail/:consentId',
				name: 'bizum-connections-detail',
				props: {
					primary: true,
					secondary: true,
				},
				components: {
					primary: () =>
						import(
							/* webpackChunkName: "v-transfers" */ '@views/v-transfers'
						),
					secondary: () =>
						import(
							/* webpackChunkName: "v-bizum-connections-detail" */ '@views/v-bizum-connections-detail'
						),
				},
			},
			{
				path: 'bizum/connections-history/:consentId',
				name: 'bizum-connections-history',
				props: {
					primary: true,
					secondary: true,
				},
				components: {
					primary: () =>
						import(
							/* webpackChunkName: "v-transfers" */ '@views/v-transfers'
						),
					secondary: () =>
						import(
							/* webpackChunkName: "v-bizum-connections-history" */ '@views/v-bizum-connections-history'
						),
				},
			},
			{
				path: 'bizum/subscriptions',
				name: 'bizum-subscriptions',
				props: {
					primary: true,
					secondary: true,
				},
				components: {
					primary: () =>
						import(
							/* webpackChunkName: "v-transfers" */ '@views/v-transfers'
						),
					secondary: () =>
						import(
							/* webpackChunkName: "v-bizum-subscriptions" */ '@views/v-bizum-subscriptions'
						),
				},
			},
			{
				path: 'bizum/subscriptions/detail/:subscriptionId',
				name: 'bizum-subscriptions-detail',
				props: {
					primary: true,
					secondary: true,
				},
				components: {
					primary: () =>
						import(
							/* webpackChunkName: "v-transfers" */ '@views/v-transfers'
						),
					secondary: () =>
						import(
							/* webpackChunkName: "v-bizum-subscriptions-detail" */ '@views/v-bizum-subscriptions-detail'
						),
				},
			},
			{
				path: 'bizum/dashboard',
				name: 'bizum-dashboard',
				props: {
					primary: true,
					secondary: true,
				},
				components: {
					primary: () =>
						import(
							/* webpackChunkName: "v-transfers" */ '@views/v-transfers'
						),
					secondary: () =>
						import(
							/* webpackChunkName: "v-bizum-dashboard" */ '@views/v-bizum-dashboard'
						),
				},
			},
			{
				path: 'bizum/details',
				name: 'bizum-details',
				props: {
					primary: true,
					secondary: true,
				},
				meta: { transition: 'fade' },
				components: {
					primary: () =>
						import(
							/* webpackChunkName: "v-transfers" */ '@views/v-transfers'
						),
					secondary: () =>
						import(
							/* webpackChunkName: "v-bizum-details" */ '@views/v-bizum-details'
						),
				},
			},
			{
				path: 'bizum/help',
				name: 'bizum-help',
				props: {
					primary: true,
					secondary: true,
				},
				meta: { transition: 'fade' },
				components: {
					primary: () =>
						import(
							/* webpackChunkName: "v-transfers" */ '@views/v-transfers'
						),
					secondary: () =>
						import(
							/* webpackChunkName: "v-bizum-help" */ '@views/v-bizum-help'
						),
				},
			},
			{
				path: 'bizum/selae',
				name: 'bizum-selae',
				props: {
					primary: true,
					secondary: true,
				},
				meta: { transition: 'fade' },
				components: {
					primary: () =>
						import(
							/* webpackChunkName: "v-transfers" */ '@views/v-transfers'
						),
					secondary: () =>
						import(
							/* webpackChunkName: "v-bizum-selae" */ '@views/v-bizum-selae'
						),
				},
			},
			{
				path: 'bizum/selae-detail',
				name: 'bizum-selae-detail',
				props: {
					primary: true,
					secondary: true,
				},
				meta: { transition: 'fade' },
				components: {
					primary: () =>
						import(
							/* webpackChunkName: "v-transfers" */ '@views/v-transfers'
						),
					secondary: () =>
						import(
							/* webpackChunkName: "v-bizum-selae-detail" */ '@views/v-bizum-selae-detail'
						),
				},
			},
			{
				path: 'bizum/unregister',
				name: 'bizum-unregister',
				props: {
					primary: true,
					secondary: true,
				},
				components: {
					primary: () =>
						import(
							/* webpackChunkName: "v-transfers" */ '@views/v-transfers'
						),
					secondary: () =>
						import(
							/* webpackChunkName: "v-bizum-unregister" */ '@views/v-bizum-unregister'
						),
				},
			},
			{
				path: 'bizum/movement/:movementId',
				name: 'bizum-movement',
				props: {
					primary: true,
					secondary: true,
				},
				components: {
					primary: () =>
						import(
							/* webpackChunkName: "v-transfers" */ '@views/v-transfers'
						),
					secondary: () =>
						import(
							/* webpackChunkName: "v-bizum-movement" */ '@views/v-bizum-movement'
						),
				},
			},
			{
				path: 'bizum/transfer/:action/:movementId?',
				name: 'bizum-transfer',
				props: {
					primary: true,
					secondary: true,
				},
				components: {
					primary: () =>
						import(
							/* webpackChunkName: "v-transfers" */ '@views/v-transfers'
						),
					secondary: () =>
						import(/* webpackMode: "lazy" */ '@views/v-bizum-transfer'),
				},
				children: [
					{
						path: 'contacts',
						name: 'bizum-contacts',
						props: true,
						component: () =>
							import(
								/* webpackChunkName: "v-bizum-contacts" */ '@views/v-bizum-contacts'
							),
					},
					{
						path: 'ongs',
						name: 'bizum-ongs',
						props: true,
						component: () =>
							import(
								/* webpackChunkName: "v-bizum-ongs" */ '@views/v-bizum-ongs'
							),
					},
				],
			},
			{
				path: 'communications/:type?',
				name: 'communications',
				props: { primary: true },
				components: {
					primary: () =>
						import(
							/* webpackChunkName: "v-communications" */ '@views/v-communications'
						),
				},
			},
			{
				path: 'communications/:type/:messageId',
				name: 'communication-detail',
				props: {
					primary: true,
					secondary: true,
				},
				components: {
					primary: () =>
						import(
							/* webpackChunkName: "v-communications" */ '@views/v-communications'
						),
					secondary: () =>
						import(
							/* webpackChunkName: "v-communication-detail" */ '@views/v-communication-detail'
						),
				},
			},
			{
				path: 'signatures/:type?',
				name: 'signatures',
				props: {
					primary: true,
					secondary: true,
				},
				components: {
					primary: () =>
						import(/* webpackChunkName: "v-global" */ '@views/v-global'),
					secondary: () =>
						import(
							/* webpackChunkName: "v-signatures" */ '@views/v-signatures'
						),
				},
			},
			{
				path: 'blocking-campaign',
				name: 'blocking-campaign',
				props: {
					primary: true,
					secondary: true,
				},
				meta: { isNavDisabled: true },
				components: {
					primary: () =>
						import(
							/* webpackChunkName: "v-blocking-campaign" */ '@views/v-blocking-campaign'
						),
					secondary: () =>
						import(
							/* webpackChunkName: "v-dynamic-page" */ '@views/v-dynamic-page'
						),
				},
			},
			{
				path: 'signatures/:type/:signatureId',
				name: 'signature-detail',
				props: {
					primary: true,
					secondary: true,
				},
				components: {
					primary: () =>
						import(/* webpackChunkName: "v-global" */ '@views/v-global'),
					secondary: () =>
						import(
							/* webpackChunkName: "v-signature-detail" */ '@views/v-signature-detail'
						),
				},
			},
			{
				path: 'correos-cash-dashboard',
				name: 'correos-cash-dashboard',
				props: {
					primary: true,
					secondary: true,
				},
				meta: { transition: 'fade' },
				components: {
					primary: () =>
						import(
							/* webpackChunkName: "v-transfers" */ '@views/v-transfers'
						),
					secondary: () =>
						import(
							/* webpackChunkName: "v-correos-cash-dashboard" */ '@views/v-correos-cash-dashboard'
						),
				},
			},
			{
				path: 'correos-cash-deposit',
				name: 'correos-cash-deposit',
				props: {
					primary: true,
					secondary: true,
				},
				meta: { transition: 'fade' },
				components: {
					primary: () =>
						import(
							/* webpackChunkName: "v-transfers" */ '@views/v-transfers'
						),
					secondary: () =>
						import(
							/* webpackChunkName: "v-correos-cash-deposit" */ '@views/v-correos-cash-deposit'
						),
				},
			},
			{
				path: 'correos-cash-detail/:depositId?/:qrId?',
				name: 'correos-cash-detail',
				props: {
					primary: true,
					secondary: true,
				},
				meta: { transition: 'fade' },
				components: {
					primary: () =>
						import(
							/* webpackChunkName: "v-transfers" */ '@views/v-transfers'
						),
					secondary: () =>
						import(
							/* webpackChunkName: "v-correos-cash-detail" */ '@views/v-correos-cash-detail'
						),
				},
			},
			{
				path: 'correos-cash/qr/:depositId/:qrId',
				name: 'correos-cash-qr',
				props: {
					primary: true,
					secondary: true,
				},
				meta: { transition: 'fade' },
				components: {
					primary: () =>
						import(
							/* webpackChunkName: "v-transfers" */ '@views/v-transfers'
						),
					secondary: () =>
						import(
							/* webpackChunkName: "v-correos-cash-qr" */ '@views/v-correos-cash-qr'
						),
				},
			},
			{
				path: 'profiles',
				name: 'profiles',
				props: {
					primary: true,
					secondary: true,
				},
				meta: { transition: 'fade' },
				components: {
					primary: () =>
						import(/* webpackChunkName: "v-global" */ '@views/v-global'),
					secondary: () =>
						import(
							/* webpackChunkName: "v-profiles" */ '@views/v-profiles'
						),
				},
			},
			{
				path: 'profiles/dashboard',
				name: 'profiles-dashboard',
				props: {
					primary: true,
					secondary: true,
				},
				components: {
					primary: () =>
						import(/* webpackChunkName: "v-global" */ '@views/v-global'),
					secondary: () =>
						import(
							/* webpackChunkName: "v-profiles-dashboard" */ '@views/v-profiles-dashboard'
						),
				},
			},
			{
				path: 'profiles/welcome',
				name: 'profiles-welcome',
				props: {
					primary: true,
					secondary: true,
				},
				components: {
					primary: () =>
						import(/* webpackChunkName: "v-global" */ '@views/v-global'),
					secondary: () =>
						import(
							/* webpackChunkName: "v-profiles-welcome" */ '@views/v-profiles-welcome'
						),
				},
			},
			{
				path: 'profiles/create/:profileId?',
				name: 'profiles-create',
				props: {
					primary: true,
					secondary: true,
				},
				components: {
					primary: () =>
						import(/* webpackChunkName: "v-global" */ '@views/v-global'),
					secondary: () =>
						import(
							/* webpackChunkName: "v-profiles-create" */ '@views/v-profiles-create'
						),
				},
			},
			{
				path: 'ontime',
				name: 'ontime',
				props: {
					primary: true,
					secondary: true,
				},
				meta: { transition: 'fade' },
				components: {
					primary: () =>
						import(/* webpackChunkName: "v-global" */ '@views/v-global'),
					secondary: () =>
						import(/* webpackChunkName: "v-ontime" */ '@views/v-ontime'),
				},
			},
			{
				path: 'ontime/dashboard',
				name: 'ontime-dashboard',
				props: {
					primary: true,
					secondary: true,
				},
				components: {
					primary: () =>
						import(/* webpackChunkName: "v-global" */ '@views/v-global'),
					secondary: () =>
						import(
							/* webpackChunkName: "v-ontime-dashboard" */ '@views/v-ontime-dashboard'
						),
				},
			},
			{
				path: 'ontime/welcome',
				name: 'ontime-welcome',
				props: {
					primary: true,
					secondary: true,
				},
				components: {
					primary: () =>
						import(/* webpackChunkName: "v-global" */ '@views/v-global'),
					secondary: () =>
						import(
							/* webpackChunkName: "v-ontime-welcome" */ '@views/v-ontime-welcome'
						),
				},
			},
			{
				path: 'ontime/create',
				name: 'ontime-create',
				props: {
					primary: true,
					secondary: true,
				},
				components: {
					primary: () =>
						import(/* webpackChunkName: "v-global" */ '@views/v-global'),
					secondary: () =>
						import(
							/* webpackChunkName: "v-ontime-create" */ '@views/v-ontime-create'
						),
				},
			},
			{
				path: 'ontime/filter',
				name: 'ontime-filter',
				props: {
					primary: true,
					secondary: true,
				},
				components: {
					primary: () =>
						import(/* webpackChunkName: "v-global" */ '@views/v-global'),
					secondary: () =>
						import(
							/* webpackChunkName: "v-ontime-filter" */ '@views/v-ontime-filter'
						),
				},
			},
		],
	},
];
